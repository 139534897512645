import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PortfolioItemSummary from "../../components/PortfolioItemSummary"
import LogoTECSight from "../../images/logo-tecsight.svg"

const PortfolioTECSight = () => {
  return (
    <Layout bgClass="bg-tecsight-blue" fgMode="dark">
      <Seo
        title="TECSight"
        description="Precision agriculture made accessible"
      />
      <section className="text-center text-black bg-tecsight-blue">
        <div className="flex flex-col items-center mx-auto lg:max-w-screen-lg">
          <LogoTECSight className="mx-auto" height={100} />
          <h1 className="mt-8 text-4xl font-semibold">Hutcheon &amp; Pearce</h1>
          <p className="mx-2 mt-2 text-2xl text-center">The purpose-led, performance-driven company.</p>
        </div>
        <PortfolioItemSummary
          expertise="UX and Development"
          platforms="Web"
          deliverables="API, Frontend"
          href="https://www.tecsight.com.au"
          linkText="TECSight" />
      </section>
      <div className="w-full mb-4 text-dsm-darkblue">
        <div className="hidden lg:grid grid-cols-[1fr,450px,1fr] grid-rows-[repeat(7,1fr)]">
          <div className="flex col-start-1 col-end-4 row-start-1 row-end-3 bg-tecsight-blue">
          </div>
          <div className="col-start-2 col-end-3 row-start-1 row-end-7">
            <StaticImage
              alt="TECSight"
              src="../../images/iphone-tecsight.png"
              placeholder="dominantColor" />
          </div>
        </div>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <p className="mb-6 text-xl">
          Hutcheon and Pearce (H&P) is an agricultural machinery sales and service business specialising in John Deere equipment.
          John Deere (JD) is a 185-year-old global machinery manufacturer focused on Sustainability, Innovation & Technology, Community and Leadership.
        </p>
        <p className="mb-6 text-xl">
          Mr. George Hutcheon and Mr. Cec. Pearce created Hutcheon and Pearce in 1953 after purchasing a Coolamon mechanical business from
          Mr J.R. Ritchie. H&P is now solely owned by Arron Hutcheon, George’s grandson via his father Ted and uncle Bruce. Since 1953, H & P
          has grown to 22 locations across NSW 18 H&P and 4 LandHQ (51% shareholding) through acquisitions of existing John Deere dealerships and new store establishment.
          Making H&P one of the largest dealers in Australia and the largest Australian family-owned JD dealership.
        </p>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-dsm-darkblue my-14">Mobile first experience</h2>
        <p className="mb-6 text-xl">
          When Hamish Ross pitched the idea to us he was adamant about a mobile first experience for the customer.
          The business wanted a near native experience bringing their customer closer to the John Deere technology &mdash;
          from the operators station inside the cab.
        </p>
        <p className="mb-6 text-xl">
          Simply scanning a QR code conveniently located within reach of the operator, takes the customer to this
          highly interactive portal where they can get support around their product stack or get linked up to John Deere's
          backend technology.
        </p>
        <p className="mb-6 text-xl">
          Hamish is driven by bringing value to Hutcheon &amp; Pearce's customers by harnessing
          the <a className="underline" href="https://developer-portal.deere.com">Develop by Deere</a> offering.
          This deployment proves the underlying technology is able to integrate with John Deere's APIs
          allowing us to build upon the vast array of what JD have to offer.
        </p>
        <p className="mb-6 text-xl">
          An <a href="https://agripark.csu.edu.au" className="underline">AgriPark</a> introduced partnership the two businesses had a round table discussion with the
          John Deere team at the recently held <a href="https://www.digitalagrifoodsummit.com.au" className="underline">Digital Agrifood
            Summit 2022</a> at Charles Sturt University to explore the potential of the John Deere technology and how we can meld it to
          work for Hutcheon &amp; Pearce's customers.
        </p>
        <p className="mb-6 text-xl">
          We're just getting started with what we can do with John Deere's technology and Hutcheon &amp; Pearce's
          deep understand of the gaps in the available solutions. John Deere's open approach by providing an open
          developer program also has us thinking about what addons we can build in the Agritech space.
        </p>
      </div>
      <div className="mx-4 text-gray-700 lg:mx-auto lg:max-w-screen-md">
        <h2 className="pb-8 text-3xl font-bold text-center border-b-4 lg:text-left border-dsm-darkblue my-14">Gatsy JS deployed on Netlify</h2>
        <p className="mb-6 text-xl">
          Gatsby JS powers many of Anomaly's web sites which we deploy on Netlify. The <span className="p-1 mx-1 font-mono bg-yellow-100 border-2 border-black rounded-sm">git</span> based workflow works rather
          well for us. We decide to bring the same stack to power TECSight bringing unprecedented flexibility and performance.
        </p>
      </div>

    </Layout>
  );
};
export default PortfolioTECSight
